import './App.scss'
import WishingWell from './components/WishingWell'

function App() {
  return (
    <div className="App">
      <WishingWell />
    </div>
  );
}

export default App;
